<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a @click="handleClose" class="nav__btn">
								<i class="icon icon-close">{{t('10642')}}</i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10643')}}
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="layer-coupon">
						<div class="box">
							<!-- [D] 일반 쿠폰 -->
							<div class="booking-box">
								<div class="box box--reverse">
									<!-- [D] 타이틀 클릭시 하단 쿠폰 리스트 토글, 타이틀에 active 클래스 추가 -->
									<h4 class="layer-coupon__title" :class="{active: openNormal}" @click="handleNormal">
									{{t('10644')}}
										<i class="icon icon-down--light"></i>
									</h4>
									<template v-if="openNormal">
										<div class="nocoupon" v-if="normalCoupons.length === 0">
											{{t('10645')}}
										</div>
										<ul class="coupon-list" v-else>
											<li>
												<div class="coupon-list__wrap">
													<label>
														<div class="radio">
															<input type="radio" class="rad blind" name="whatCoupon" @click="handleNo" :value="selectNormalCoupon === undefined">
															<span class="radio__bg"></span>
															<div class="coupon-list__title">
																{{t('10646')}}
															</div>
														</div>
													</label>
												</div>
											</li>
											<li v-for="coupon in normalCoupons" :key="coupon.CP_NO">
												<div class="coupon-list__wrap" :class="{disabled: coupon.USE_YN !== 'Y'}">
													<label>
														<div class="radio">
															<input type="radio" class="rad blind" name="whatCoupon" :disabled="coupon.USE_YN !== 'Y'" :value="{...coupon, hotelNo: selectCouponHotelNo}" v-model="selectNormalCoupon">
															<span class="radio__bg"></span>
															<div class="coupon-list__name ellipsis">
																{{coupon.CP_NM}}
															</div>
															<div class="coupon-list__title">
																<strong>{{coupon.CP_AMT > 0 ? coupon.CP_AMT+"원" : coupon.CP_DCRATE + "%"}}</strong>{{t('10647')}}
															</div>
															<div class="coupon-list__desc">
																{{coupon.CP_DESC}}
															</div>
														</div>
													</label>
												</div>
											</li>
										</ul>
									</template>
								</div>
							</div>
							<!-- [D] 중복 쿠폰 -->
							<div class="booking-box">
								<div class="box box--reverse">
									<!-- [D] 타이틀 클릭시 하단 쿠폰 리스트 토글, 타이틀에 active 클래스 추가 -->
									<h4 class="layer-coupon__title" :class="{active: openMultiple}" @click="handleMultiple">
										{{t('10648')}}
										<i class="icon icon-down--light"></i>
									</h4>
									<template v-if="openMultiple">
										<div class="nocoupon" v-if="multipleCoupons.length === 0">
											{{t('10649')}}
										</div>
										<ul class="coupon-list" v-else>
											<li v-for="coupon in multipleCoupons" :key="coupon.CP_NO">
												<!-- [D] 사용불가 쿠폰일 경우 disabeld 클래스 추가 -->
												<div class="coupon-list__wrap" :class="{disabled: coupon.USE_YN !== 'Y'}">
													<label>
														<div class="checkbox">
															<input type="checkbox" class="chk blind" name="whatCoupon" :disabled="coupon.USE_YN !== 'Y'" :value="{...coupon, hotelNo: selectCouponHotelNo}" v-model="selectMultipleCoupons">
															<span class="checkbox__bg"></span>
															<div class="coupon-list__name ellipsis">
																{{coupon.CP_NM}}
															</div>
															<div class="coupon-list__title">
																<strong>{{coupon.CP_AMT > 0 ? coupon.CP_AMT + "원" : coupon.CP_DCRATE + "%"}}</strong>{{t('106437')}}
															</div>
															<div class="coupon-list__desc">
																{{coupon.CP_DESC}}
															</div>
														</div>
													</label>
												</div>
											</li>
										</ul>
									</template>
								</div>
							</div>
						</div>
						<div class="row">
							<!-- <p v-if="complete" class="red description">최종 할인금액은 0원보다 낮은 금액으로 설정할 수 없습니다.</p> -->
							<button type="button" class="btn" :class="'btn-submit'" @click="handleSubmit" >{{t('10650')}}</button>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { watchEffect } from '@vue/runtime-core';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	props: ["handleClose", "selectedCouponByOrderId", "selectedCoupons", "selectCouponHotelNo", "handleSelectCoupons", "mrm0206", "order", "orderId"],
	setup(props) {
		const openNormal = ref(true);
		const openMultiple = ref(true);
		const { t }= useI18n() 
		const coupons = computed(() => {
			return props.mrm0206[props.selectCouponHotelNo].filter(coupon => {
				const isUsedCoupon = Object.keys(props.selectedCouponByOrderId).find(orderId => {
					const sameCoupon = props.selectedCouponByOrderId[orderId].find(selectedCoupon => {
						//console.log(selectedCoupon.CP_NO)
						return selectedCoupon.CP_NO === coupon.CP_NO
					})
					return (
						props.orderId !== Number(orderId) && 
						sameCoupon 
					);
				});
				return isUsedCoupon === undefined;
			})
		});
		const selectNormalCoupon = ref();
		const selectMultipleCoupons = ref([]);

		watchEffect(() => {
			selectNormalCoupon.value = props.selectedCoupons.filter(coupon => coupon.MULTIPLE_USE !== "Y")?.[0];
			selectMultipleCoupons.value = props.selectedCoupons.filter(coupon => coupon.MULTIPLE_USE === "Y");
		});

		const handleNormal = () => {
			openNormal.value = !openNormal.value;
		}

		const handleMultiple = () => {
			openMultiple.value = !openMultiple.value;
		}

		// const complete = computed(() => {
		// 	let dcPrice = selectMultipleCoupons.value.reduce((acc, cur) => acc + (cur.CP_AMT !== 0 ? cur.CP_AMT : Math.round(props.order.price * cur.CP_DCRATE / 100)), 0);
		// 	if(selectNormalCoupon.value) {
		// 		dcPrice += selectNormalCoupon.value.CP_AMT !== 0 ? selectNormalCoupon.value.CP_AMT : Math.round(props.order.price * selectNormalCoupon.value.CP_DCRATE / 100);
		// 	}
		// 	return props.order.price - dcPrice >= 0;
		// })

		const handleSubmit = () => {
			props.handleSelectCoupons(selectNormalCoupon.value !== undefined ? [selectNormalCoupon.value, ...selectMultipleCoupons.value] : selectMultipleCoupons.value);
			props.handleClose();
		}

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
		
		return {
			openNormal,
			openMultiple,
			handleNormal,
			handleMultiple,
			normalCoupons: computed(() => coupons.value.filter(coupon => coupon.MULTIPLE_USE !== "Y")),
			multipleCoupons: computed(() => coupons.value.filter(coupon => coupon.MULTIPLE_USE === "Y")),
			// complete,
			selectNormalCoupon,
			selectMultipleCoupons,
			t,  //번역필수 모듈
		  i18n, 
			handleNo() {
				selectNormalCoupon.value = undefined
			},
			handleSubmit
		}
	},
}
</script>